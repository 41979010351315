<template>
  <div class="container is-max-desktop">
    <div class="columns is-mobile page_forbidden">
      <div class="column">
        <img src="../../assets/images/amico.png" alt="" />
      </div>
      <div class="column">
        <div class="text_content">
          <h1 class="header_text">403 Access Forbidden</h1>
          <p class="text_body">
            The page you’re trying to go has restricted access. Please refer to
            your system administrator.
          </p>
        </div>
        <button @click="goToDAshboard()" class="btn_forbidden">
          Back to Dashboard
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToDAshboard() {
      this.$router.push('/')
    },
  },
}
</script>

<style></style>
